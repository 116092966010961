body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100dvh;
  display: grid;
  grid-template-rows: min-content 1fr;
  align-content: flex-start;
  overflow-y: auto;
}
@supports not (height: 100dvh) {
  :root {
    height: 100vh;
  }
}

.hidden {
  visibility: hidden;
}

/* pdf text remains selectable under overlay */
.react-pdf__Page__textContent {
  z-index: 5;
}

/* remove strange whitespace at the bottom when zoomed out */
.react-pdf__Page {
  height: fit-content;
}

.useFreehandMode react-pdf__Document,
.useFreehandMode .react-pdf__Document .react-pdf__Page__textContent,
.useFreehandMode .react-pdf__Document .react-pdf__Page__textContent span,
.useFreehandMode .react-pdf__Document .react-pdf__Page__textContent .endOfContent {
  cursor:crosshair;
}

/* remove all styling from hyperlinks */
.remove-link-decoration {
  all: unset;
  cursor: pointer;
}

/* bubble down scroll events */
.overflowWrapper {
  display: grid;
  overflow: auto;
  width: 100%;
}

/* style scrollbars in chrome and safari browsers  */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover{
  border: 0;
}
::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (max-width: 400px) {
  emoji-picker {
    --num-columns: 6;
    --category-emoji-size: 1.125rem;
  }
}
